<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
        <ul>
          <li class="pb-3"> Brecher, D. (2021). <em>Resiliency Quiz</em>. Strong Minds Strong Kids, Psychology Canada. <a href="https://strongmindsstrongkids.org/Public/Resources/Resiliency-Quiz/Public/Resources/Resiliency-Quiz.aspx?hkey=c84d5d68-a275-44c9-a8d1-c668e6290815" target="_blank">strongmindsstrongkids.org/Public/Resources/Resiliency-Quiz/Public/Resources/Resiliency-Quiz.aspx?hkey=c84d5d68-a275-44c9-a8d1-c668e6290815</a></li>
          <li class="pb-3"> Ryerson University. (2021). <em>ThriveRU Resilience Flash Cards: Building Skills for a Healthy Life.</em></li>
          <li class="pb-3"> Brecher, D. (2020, April 14). <em>Three Minute Breathing Space Meditation with Dr. Diana Brecher</em> [Video]. First-Year Engineering Office FYEO. <br><a href="https://www.youtube.com/watch?v=UvqYDiD7MsU" target="_blank" >www.youtube.com/watch?v=UvqYDiD7MsU</a> </li>
          <li class="pb-3">Brecher, D. (2020, April 24). <em>Ocean Meditation with Dr. Diana Brecher</em> [Video]. First-Year Engineering Office FYEO. <a href="https://www.youtube.com/watch?v=QplAeYGXHXA" target="_blank">www.youtube.com/watch?v=QplAeYGXHXA</a></li>
          <li class="pb-3">Brecher, D. (2020, May 15). <em>Lake Meditation with Dr. Diana Brecher</em> [Video]. First-Year Engineering Office FYEO. <a href="https://www.youtube.com/watch?v=vsa30_myJL0" target="_blank">www.youtube.com/watch?v=vsa30_myJL0</a></li>
          <li class="pb-3">Shaffer, D.K. (2020, May 1). <em>Loving Kindness Meditation with Dr. Deena Kara Shaffer</em> [Video]. First-Year Engineering Office FYEO<br><a href=" https://www.youtube.com/watch?v=L-LKxFRq-jE" target="_blank">www.youtube.com/watch?v=L-LKxFRq-jE</a></li>
          <li>Ryerson University, Shaffer, D. K., & Brecher, D. (2019). <em>Thriving in Action Online</em>. Thriving in Action Online.<br> <a href="https://tia.ryerson.ca/" target="_blank">tia.ryerson.ca/</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '09',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
